<template>
  <div>
    <form
      id="myFormAdd"
      name="mikrotikAdd"
      action="https://hotspot.sociowifi.com/login"
      method="post"
      ref="mikrotik"
    >
      <!-- action="https://hotspot.sociowifi.com/login" -->
      <!-- action="https://www.modern-networks.net/login" -->
      <input type="hidden" name="username" :value="username" />
      <input type="hidden" name="password" value="password" />
      <input type="hidden" name="dst" value="https://www.google.co.th" />
      <input type="hidden" name="popup" value="false" />
      <input name="btnSubmit" hidden type="submit" value="Submit" />
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      action: "",
      username: "0895701261",
      dst: "",
    };
  },
  created() {
    console.log("this.$route.query :>> ", this.$route.query);
  },
  mounted() {
    this.username = this.$route.query.username;
    // http://localhost:8080/captive?username=089571261
    setTimeout(() => {
        this.$refs.mikrotik.submit();
    }, "1500");
  },
};
</script>

<style lang="scss" scoped>
</style>